import React from "react"
import Layout from "../components/layout"
import NewsletterComp from "../components/newsletter"
import Seo from "../components/Seo/seo"

export default function Newsletter() {
  return (
    <Layout>
      <Seo
        title="Get our Newsletter"
        description="Receive the latest updates, discounts, reductions and special rates for the tour in Costa Rica"
      />
      <h1 className="d-none">Subscribe to our Newsletter</h1>
      <NewsletterComp />
    </Layout>
  )
}
